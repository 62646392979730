import * as React from "react";

export interface IPiskotkiProps {}

export function Piskotki(props: IPiskotkiProps) {
  return (
    <div>
      <h1>Stran je v izdelavi</h1>
      <p>
        Spletna stran je še v procesu izdelave in ni namenjena splošni rabi.
      </p>
    </div>
  );
}

export default Piskotki;
